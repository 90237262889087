<template>
  <div> <!-- eureg-domain-details -->
    <div class="row">
      <div class="col-xs-12">
        <div class="panel panel-default">
          <div class="panel-body">
            <div class="row">
              <div class="col-xs-12">
                <span class="is-size-5 text-primary"><b>General Details</b></span>
                <hr class="small-margin">
              </div>
            </div>
            <template v-if="remote.ace_name">
            <div class="row medium-margin">
              <div class="col-xs-12 col-sm-3">
                <span class="is-size-6"><b>ACE string</b></span>
                <hr class="visible-xs hr-styled no-margin">
              </div>
              <div class="col-xs-12 col-sm-9">
                <span class="is-size-6" v-text="remote.ace_name"></span>
              </div>
            </div>
            </template>

          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'eureg-domain-details',
  props: {
    value: {
      type: Object,
      default: () => {
      }
    },
  },
  watch: {
    value: function(newVal, oldVal) {
      this.$set(this, 'remote', newVal);
    },
  },
  data() {
    return {
      remote: this.value,
    }
  },
}
</script>