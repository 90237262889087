
const isIp = require('is-ip');

const isValidHostname = function (value) {
    if (typeof value !== 'string') return false;

    // wildcard is allowed as a single character
    if (value === '*') return true;

    const validHostnameChars = /^[a-zA-Z0-9-_.]{1,253}\.?$/g;
    if (!validHostnameChars.test(value)) {
        return false;
    }

    if (value.endsWith('.')) {
        value = value.slice(0, value.length - 1);
    }

    if (value.length > 253) {
        return false;
    }

    const labels = value.split('.');

    const isValid = labels.every(function (label) {
        const validLabelChars = /^([a-zA-Z0-9-_]+)$/g;

        const validLabel = (
            validLabelChars.test(label) &&
            label.length < 64 &&
            !label.startsWith('-') &&
            !label.endsWith('-')
        );

        return validLabel;
    });

    return isValid;
}

export { isValidHostname, isIp }