<template>
  <div class="panel panel-default is-flex flex-dir-column" style="min-height: 200px;">
    <div class="panel-body text-center" style="margin-top: auto; margin-bottom: auto;">
      <p class="is-size-7">
        <i class="fas fa-fw fa-spinner fa-spin text-primary"></i> {{ $t('app.loading')}}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'eureg-loading-panel',
}
</script>