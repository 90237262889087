<template>
  <div class="input-group">
      <input v-bind:type="showPassword ? 'text' : 'password'"
             class="form-control"
             v-bind="$attrs"
             v-on:input="handleInput"
             v-model="password"
             autocomplete="off"
      >
      <div class="input-group-addon" @click="showPassword=!showPassword">
        <i v-if="showPassword" class="fa-fw far fa-eye"></i>
        <i v-else class="fa-fw far fa-eye-slash"></i>
      </div>
  </div>
</template>

<script>
export default {
  name: 'eureg-input-password',

  data() {
    return {
      showPassword: false,
      password: null,
    }
  },
  methods: {
    // v-model expects an @input event
    handleInput(e) {
      this.$emit('input', this.password)
    },
  }

}

</script>