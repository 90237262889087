<template>
  <div> <!-- input-rows-container -->
      <div class="row" v-for="(row,index) in newList">
          <template v-for="(field, field_index) in $props.fields">
              <div :class="field.width ? field.width : ''">
                  <div class="form-group has-feedback small-margin">
                      <label v-text="field.label"></label>
                      <div style="position: relative;">
                          <input
                              v-bind="field.attrs ? field.attrs : null"
                              ref="inputRow"
                              class="form-control"
                              v-model="newList[index][field.name]"
                              v-on:input="handleInput">
                          <template v-if="$props.fields.length === field_index + 1">
                              <div class="input-inside-button" @click="removeEntry(index)">
                                <i class="fa fa-times text-muted hover-red"></i>
                              </div>
                              <div v-if="canAddElements && newList.length && index === newList.length - 1"
                                   class="input-outside-button"
                                   @click="addEntry">
                                <i class="fa fa-plus hover-primary"></i>
                              </div>
                          </template>
                        <div v-if="field.help" class="text-muted" v-text="field.help"></div>
                      </div>
                  </div>
              </div>
          </template>
      </div>
  </div>
</template>

<script>
export default {
  name: 'eureg-input-rows',
  props: {
    value: {
      type: Array,
      default: () => []
    },

    limit: {
      type: Number,
      default: -1,
    },
    
    fields: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    value: function(newVal, oldVal) {
      console.log("watch.value newVal=", newVal);
      this.$set(this, 'newList', newVal);
      if (this.newList.length === 0) {
        this.newList.push({});
      }
    }
  },
  data() {
    return {
      newList: this.$props.value,
    }
  },
  created: function() {
    if (this.newList.length === 0) {
      this.newList.push({});
    }
  },
  methods: {
    // v-model expects an @input event
    handleInput (e) {
      this.$emit('input', this.newList)
    },

    removeEntry: function(index) {
      if (index != null)
        this.newList.splice(index, 1)

      if (this.newList.length === 0) {
        this.newList.push({});
      }
      this.handleInput()
    },

    addEntry: function() {
      // we already have the maximum number of elements
      if (!this.canAddElements) {
        return false;
      }
      this.newList.push({});
      // next tick we focus on the newly added input
      this.$nextTick(() => this.$refs.inputRow[this.newList.length - 1].focus())
      this.handleInput()
    },
  },
  computed: {
    canAddElements: function() {
      return !(this.$props.limit > 0
          && this.newList
          && this.newList.length >= this.$props.limit);
    }

  },
}
</script>