import axios from "axios";

class EuregApi {
    constructor() {
        this.http = axios.create({
            baseURL: "/api/v1",
            timeout: 5000,
        });
    }

    async domainDetails(domainId) {
        if (!domainId) {
            throw "No domain to load";
        }

        if (process.env.NODE_ENV !== 'production') {
            console.log("[api.js] domainDetails(" + domainId + ")");
        }

        return this.get("/domain/" + domainId + "?with=services");
    }

    async get(apiMethod, params) {
        const response = await this.http.get(apiMethod, params);
        return response.data;
    }

    async post(apiMethod, params) {
        const response = await this.http.post(apiMethod, params);
        return response.data;
    }

    async put(apiMethod, params) {
        const response = await this.http.put(apiMethod, params);
        return response.data;
    }

    async delete(apiMethod, params) {
        const response = await this.http.delete(apiMethod, params);
        return response.data;
    }
}

export default EuregApi;