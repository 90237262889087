const _ = require('lodash');
window._ = _;

// shared across all Vue instances on the page
window.sharedData = {
    //isLoading: false,
    cart: {
        must_load: true,
        loaded: false,
        products: [],
    },
    app: {
        isLoading: true,
    },
}

window.webApi = {
    data: {
        loggedUser: $loggedUser,
        //isLoading: sharedData.isLoading,
        cart: window.sharedData.cart,
    },
    computed: {
        cartTotal: function() {
            let total = 0;
            this.cart.products.forEach( x => {
                total += x.amount + x.extraAmount;
            });
            return new Intl.NumberFormat($locale, { style: 'currency', currencyDisplay: 'symbol', currency: 'EUR' }).format(total);
        }
    },
    methods: {
        checkout: function() {
            this.saveCart(function(){
                window.location = window.baseLangUrl + "checkout";
            });
        },
        gotoCart: function() {
            this.saveCart(function(){
                window.location = window.baseLangUrl + "register/update-cart";
            });
        },
        updatePeriod: function(row, increment) {
            if (process.env.NODE_ENV !== 'production') {
                console.log("Adding " + increment + " period to " + row.name);
            }
            let newPeriod = row.period + increment;
            if (newPeriod >= row.min && newPeriod <= row.max) {
                if (process.env.NODE_ENV !== 'production') {
                    console.log("Update period for " + row.name + " to period: ", newPeriod);
                }
                this.$set(row, 'period', newPeriod);
                let amount = parseFloat(row.unit_price) + (newPeriod - 1) * parseFloat(row.renew);
                this.$set(row, 'amount', amount);
                this.$set(row, 'amountFormatted', new Intl.NumberFormat($locale, { style: 'currency', currencyDisplay: 'symbol', currency: 'EUR' }).format(amount));
            }
            let sumExtra = 0;

            /* update the price of extras options */
            if (row.extra) {
                row.extra.forEach(x => {
                    this.updateExtra(x, 0);
                    sumExtra += x.amount;
                });
            }
            this.$set(row, 'extraAmount', sumExtra);
            this.$set(row, 'extraAmountFormatted', new Intl.NumberFormat($locale, { style: 'currency', currencyDisplay: 'symbol', currency: 'EUR' }).format(sumExtra));
        },
        updateExtra: function(extra, amount) {
            let newQty = extra.qty + amount;

            if (newQty >= extra.min && newQty <= extra.max) {
                this.$set(extra, 'qty', newQty);
                let amount = newQty * parseFloat(extra.unit_price);
                this.$set(extra, 'amount', amount);
                this.$set(extra, 'amountFormatted', new Intl.NumberFormat($locale, { style: 'currency', currencyDisplay: 'symbol', currency: 'EUR' }).format(amount));
            }
        },
        updateProducts: function() {
            this.cart.products.forEach( x => this.updatePeriod(x, 0));
        },
        isInCart: function (name) {
            return !!this.cart.products.filter(x => x.name === name).length
        },
        addToCart: function (row) {
            if (this.isInCart(row.name)) {
                return;
            }
            this.isLoading = true;
            this.$set(row, 'isLoading', true);

            let apiURL = window.baseLangUrl + "web-api/cart";

            axios.post(apiURL, row)
                .then(response => {
                    this.$set(row, 'inCart', true);
                    //this.cart.products.push(row);
                    if (process.env.NODE_ENV !== 'production') {
                        console.log("Added to cart ", row);
                    }
                    this.parseRemoteCartData(response.data.data);
                })
                .catch(e => {
                    //this.errorMessage = e.response.data.message;
                })
                .then(() => {
                    this.$delete(row, 'isLoading');
                    this.isLoading = false;
                });
        },
        removeFromCart: function (row) {
            this.isLoading = true;
            this.$set(row, 'isLoading', true);

            let apiURL = window.baseLangUrl + "web-api/cart/" + row.name;

            axios.delete(apiURL)
                .then(response => {
                    this.$delete(row, 'inCart');
                    this.$delete(row, 'isHover');
                    //let idx = this.cart.products.findIndex(x => x.name === row.name);
                    //this.$delete(this.cart.products, idx);
                    this.parseRemoteCartData(response.data.data);
                })
                .catch(e => {
                    //this.errorMessage = e.response.data.message;
                })
                .then(() => {
                    this.$delete(row, 'isLoading');
                    this.isLoading = false;
                });
        },

        parseRemoteCartData: function(newData) {
            this.cart = Object.assign(this.cart, newData);
            if (process.env.NODE_ENV !== 'production') {
                console.log("loadCart will emit event api-cart-loaded");
            }
            this.$emit('api-cart-loaded');
            this.cart.loaded = true;
            this.updateProducts();
            this.isLoading = false;
        },

        loadCart: function() {
            this.cart.must_load = false;
            this.isLoading = true;
            let apiURL = window.baseLangUrl + "web-api/cart";

            axios.get(apiURL)
                .then( response => {
                    this.parseRemoteCartData(response.data.data);
                })
                .catch(e => {
                })
                .then(() => {
                });
        },
        saveCart: function(callback) {
            this.isLoading = true;
            let apiURL = window.baseLangUrl + "web-api/cart";

            axios.put(apiURL, this.cart)
                .then( response => {
                    callback(response);
                })
                .catch(e => {
                    //this.errorMessage = e.response.data.message;
                })
                .then(() => {
                    this.isLoading = false;
                });
        },

    },
    mounted: function() {
        if (process.env.NODE_ENV !== 'production') {
            console.log("webApi mixin mounted");
        }
        if (this.cart.must_load) {
            this.loadCart();
        }
    }
};

window.pusherMixin = {
    data : {
        pusherMessages: [],
    },
    methods: {
        pushMessageReceived: function(msg) {
            if (!msg || !msg.body)
                return false;

            if (!msg.type) {
                msg.type = "info";
            }

            let status = {
                type: msg.type,
                content: msg.body,
                html: true,
                duration: 30 * 1000,
            };

            this.$notify(status);
        },
    },
    mounted: function() {
        if (process.env.NODE_ENV !== 'production') {
            // Enable pusher logging - don't include this in production
            Pusher.logToConsole = true;
        }
        let pusher = new Pusher('99915a8e75a43a0e6061', {
            cluster: 'eu'
        });

        if (window.$eureg && window.$eureg.key) {
            let channel = pusher.subscribe('user-' + window.$eureg.key);
            channel.bind('eureg:event', this.pushMessageReceived);
        }

        this.$pusher = pusher;
        window.pusher = pusher;
        if (process.env.NODE_ENV !== 'production') {
            console.log("pusherMixin mounted");
        }
    },
};

window.searchMixin = {
    data: {
        inputNames: [],
        retries: 0,
    },
    computed: {
        stillChecking: function() {
            return !!this.inputNames.filter(x => x.status === 'PENDING').length;
        },

    },
    methods: {
        isHover: function(row, value) {
            if (value) {
                this.$set(row, 'isHover', value);
            } else {
                this.$delete(row, 'isHover');
            }
        },
        parseRemoteData: function(remote) {
            for(i=0; i < remote.length; i++) {
                if (process.env.NODE_ENV !== 'production') {
                    console.log("Parsing domain: ", remote[i]);
                }
                let idx = this.inputNames.findIndex(x => x.name === remote[i].name);
                if (idx >= 0) {
                    if (process.env.NODE_ENV !== 'production') {
                        console.log("Found domain " + remote[i].name + " at idx ", idx);
                    }
                    this.$set(this.inputNames, idx, remote[i]);
                } else {
                    if (process.env.NODE_ENV !== 'production') {
                        console.log("Adding domain to the list");
                    }
                    this.inputNames.push(remote[i]);
                }
            }

            if (this.stillChecking) {
                if (this.retries < 10) {
                    if (process.env.NODE_ENV !== 'production') {
                        console.log("We have to schedule another remote pull");
                    }
                    this.retries++;
                    setTimeout(() => {
                        this.queryResults()
                    }, 1000)
                } else {
                    // mark as error
                    this.markAsError();
                }
            }
        },
        markAsError: function() {
            this.inputNames.filter(x => x.status === 'PENDING').map(x => x.status = 'ERROR');
        },

        queryName: function(name) {

        },
        queryResults: function() {

            // still have items pending?
            let list = this.inputNames.filter(x => x.status === 'PENDING').map(x => x.name);
            if (!list || !list.length) {
                return;
            }

            let apiURL = window.baseLangUrl + "web-api/check-multi/?names=" + list.join(',');
            if (process.env.NODE_ENV !== 'production') {
                console.log("Will query " + apiURL);
            }

            axios.get(apiURL)
                .then( response => {
                    this.parseRemoteData(response.data.data);
                    //this.successMessage = response.data.data.message;
                })
                .catch(e => {
                    //this.errorMessage = e.response.data.message;
                })
                .then(() => {
                    //this.isSaving = false;
                });
        }
    },
    mounted: function() {
        setTimeout(() => {
            this.queryResults()
        }, 1500)
    }
};



// used in transfer/verify.tpl
window.verifyMixin = {
    data: {
        retries: 0,
        domains: [],
    },
    computed: {
        stillChecking: function() {
            return !!this.inputNames.filter(x => x.status === 'PENDING').length;
        },
    },
    methods: {
        apiTransfer: function(row) {
            this.$set(row, 'isLoading', true);
            this.$delete(row, 'error');

            let apiURL = window.baseLangUrl + "web-api/transfer";

            if (process.env.NODE_ENV !== 'production') {
                console.log("Will start transfer " + apiURL);
            }

            axios.post(apiURL, { "domain" : row.name, "transfer_key": row.transfer_code})
                .then( response => {
                    if (process.env.NODE_ENV !== 'production') {
                        console.log("SUCCESS reply:", response.data);
                    }
                    this.$set(row, "status", "DONE");
                    this.$delete(row, 'eppStatus');
                    //this.successMessage = response.data.data.message;
                })
                .catch(e => {
                    let msg = e.response && e.response.data ? e.response.data.result_message : "Network error";
                    this.$set(row, 'error', msg);
                    //this.errorMessage = e.response.data.message;
                })
                .then(() => {
                    this.$delete(row, 'isLoading');
                });
        },

        isHover: function(row, value) {
            if (value) {
                this.$set(row, 'isHover', value);
            } else {
                this.$delete(row, 'isHover');
            }
        },
        parseRemoteData: function(remote) {
            for(i=0; i < remote.length; i++) {
                if (process.env.NODE_ENV !== 'production') {
                    console.log("Parsing domain: ", remote[i]);
                }
                let idx = this.inputNames.findIndex(x => x.name === remote[i].name);
                if (idx >= 0) {
                    if (process.env.NODE_ENV !== 'production') {
                        console.log("Found domain " + remote[i].name + " at idx ", idx);
                    }
                    this.$set(this.inputNames, idx, remote[i]);
                } else {
                    if (process.env.NODE_ENV !== 'production') {
                        console.log("Adding domain to the list");
                    }
                    this.inputNames.push(remote[i]);
                }
            }

            if (this.stillChecking) {
                if (this.retries < 10) {
                    if (process.env.NODE_ENV !== 'production') {
                        console.log("We have to schedule another remote pull");
                    }
                    this.retries++;
                    setTimeout(() => {
                        this.queryResults()
                    }, 1000)
                } else {
                    // mark as error
                    this.markAsError();
                }
            }
        },
        markAsError: function() {
            this.inputNames.filter(x => x.status === 'PENDING').map(x => x.status = 'ERROR');
        },
        recheckStatus: function(row) {
            this.$set(row, 'force_check', true);
            this.queryResults();
        },
        queryResults: function() {

            // still have items pending?
            let list = this.inputNames.filter(x => x.status === 'PENDING' || x.force_check).map(x => x.name);
            if (!list || !list.length) {
                return;
            }

            let apiURL = window.baseLangUrl + "web-api/check-transfer?names=" + list.join(',');
            if (process.env.NODE_ENV !== 'production') {
                console.log("Will query " + apiURL);
            }

            axios.get(apiURL)
                .then( response => {
                    this.parseRemoteData(response.data.data);
                    //this.successMessage = response.data.data.message;
                })
                .catch(e => {
                    //this.errorMessage = e.response.data.message;
                })
                .then(() => {
                    //this.isSaving = false;
                });

        },
        hasStatus: function(row, status) {
            // no eppStatus for this domain
            if (!row.eppStatus)
                return false;

            return row.eppStatus.find(x => x === status);
        }
    },
    mounted: function() {
        if (process.env.NODE_ENV !== 'production') {
            console.log("verifyMixin mounted");
        }
        if (this.stillChecking) {
            setTimeout(() => {
                this.queryResults()
            }, 1500)
        }
    }
};


window.domainNameserversMixin = {
    data: {
        newHost: {},
        selectedType: undefined,
        editMode: false,
        remote: {},
        isSaving: false,
        errorMessage: undefined,
        successMessage: undefined,
    },
    computed: {
        haveChanges: function() {
            if (!this.remote || !this.defaultValues)
                return false;

            return JSON.stringify(this.remote.nameservers) !== JSON.stringify(this.defaultValues.nameservers);
        },
        useNameservers: function() {
            if (this.remote
                && this.remote.nameservers
                && this.remote.nameservers.length > 0) {

                if (this.remote.nameservers.length === 2
                    && this.remote.nameservers[0].Name.match(/park[12].eureg.ro/)
                    && this.remote.nameservers[1].Name.match(/park[12].eureg.ro/)
                ) {
                    return 'EUREG';
                }
                return 'CUSTOM';
            }
            return undefined;
        },
    },
    methods: {
        changedType() {
            this.errorMessage = undefined;

            if (this.selectedType === 'EUREG') {
                this.editMode = false;
                this.remote.nameservers = [];
                this.remote.nameservers.push({"Pos": 1, "Name" : "park1.eureg.ro", "IP": ""});
                this.remote.nameservers.push({"Pos": 2, "Name" : "park2.eureg.ro", "IP": ""});
            } else if (this.selectedType === 'CUSTOM') {
                this.remote.nameservers = this.defaultValues.nameservers;

                if (this.useNameservers === 'EUREG') {
                    this.remote.nameservers = [];
                }
                if (this.remote.nameservers.length === 0) {
                    this.remote.nameservers.push({ "Name" : ""});
                    this.editNameservers();
                }
            }
        },

        finishEditName: function(ev, index) {
            let nsName = ev.target.value.toLowerCase();
            let isGlue = false;

            // a single hostname, append the domain
            if (!nsName.match(/\./)) {
                nsName = nsName + "." + this.remote.domain;
            }

            if (nsName.length &&
                (nsName === this.remote.domain
                    || !nsName.match(/\./)
                    || nsName.endsWith("." + this.remote.domain))) {
                let hostCreated = null;

                // search if this hostname is already created as glue record
                if (this.remote.hosts && this.remote.hosts.length) {
                    hostCreated = this.remote.hosts.find(o => o.hostname.toLowerCase() === nsName);
                }

                if (!hostCreated) {
                    isGlue = true;
                }
            }

            this.$set(this.remote.nameservers[index], 'fullName', nsName);
            this.$set(this.remote.nameservers[index], 'isGlue', isGlue);
            this.$set(this.remote.nameservers[index], 'ips', []);
        },

        removeNsEntry: function(index) {
            if (index != null)
                this.remote.nameservers.splice(index, 1)

            if (this.remote.nameservers.length === 0) {
                this.addNsEntry();
            }
        },
        addNsEntry: function() {
            this.remote.nameservers.push({});
            // next tick we focus on the newly added input
            this.$nextTick(() => this.$refs.inputRow[this.remote.nameservers.length - 1].focus())
        },
        editNameservers() {
            this.editMode = true;
            this.errorMessage = undefined;
        },
        saveNameservers() {
            let url = "/domain/" + this.domainId + "/nameservers";

            this.isSaving = true;
            this.errorMessage = undefined;
            this.successMessage = undefined;

            this.$api.post(url, this.$data.remote.nameservers)
                .then( apiResult => {
                    this.successMessage = apiResult.message;

                    this.$vToastify.success({
                        canTimeout: true,
                        hideProgressbar: true,
                        duration: 2000,
                        title: "Success!",
                        body: "<span class=\"h3\">" + apiResult.message + "</span>",
                        type: "success"
                    });
                    this.loadDomain();
                })
                .catch(e => {
                    this.errorMessage = e.response && e.response.data ? e.response.data.message : "Network error";
                })
                .then(() => {
                    this.isSaving = false;
                });
        },
        cancelEdit() {
            this.remote.nameservers = this.defaultValues.nameservers;
            if (this.useNameservers === 'EUREG') {
                this.selectedType = this.useNameservers;
            }
            this.editMode = false;
            this.errorMessage = undefined;
            this.successMessage = undefined;
        },

        checkNameserver: function(element, index) {
            var ns = Object.assign({}, this.remote.nameservers[index]);
            ns.isGlue = false;
            if (ns.IP && ns.IP.length) {
                ns.ips = ns.IP.split(",");
                ns.isGlue = true;
            }
            if (ns.Name.length &&
                (ns.Name === this.remote.domain
                    || !ns.Name.match(/\./)
                    || ns.Name.endsWith("." + this.remote.domain))) {
                ns.isGlue = true;
            }
            this.remote.nameservers[index] = Object.assign({}, ns);
        },
        parseNameservers: function() {
            this.remote.nameservers.forEach(this.checkNameserver);
        },

        renewDomain(domainId, forwardUrl, price) {
            let url = "/domain/" + this.domainId + "/renew";

            if (confirm(this.$t('confirm.renew', { price: price }))) {
                this.isSaving = true;
                this.$api.post(url)
                    .then(apiResult => {
                        // reload the page at the next update
                        this.$nextTick(() => window.location = window.location);

                        this.$vToastify.success({
                            canTimeout: true,
                            hideProgressbar: true,
                            duration: 2000,
                            title: "Success!",
                            body: "<span class=\"h3\">" + apiResult.message + "</span>",
                            type: "success"
                        });
                    })
                    .catch(e => {
                    })
                    .then(() => {
                        this.isSaving = false;
                    });
            }
        },

        async loadDomain() {

            try {
                this.app.isLoading = true;
                if (process.env.NODE_ENV !== 'production') {
                    console.log("Loading domainId:", this.domainId);
                }

                let apiResult = await this.$api.domainDetails(this.domainId);
                this.remote = Object.assign({}, apiResult.data);
                this.$set(this.remote, 'hosts', apiResult.data.hosts);

                this.defaultValues = Object.assign({}, this.remote);
                this.selectedType = this.useNameservers;
                this.$emit('details-loaded', this.domainId);
                this.parseNameservers();
            } catch (e) {

            } finally {
                this.editMode = false;
                this.app.isLoading = false;
            }
        },

        editHost: function(host, editMode) {
            // save the values
            if (editMode) {
                host.savedIp4 = [...host.ip];
                host.savedIp6 = [...host.ip6];
            }
            this.$set(host, 'editMode', editMode);
        },

        saveHost: function(host, isNew) {
            let url = "/domain/" + this.domainId + "/hosts/" + host.hostname;

            this.isSaving = true;
            this.errorMessage = undefined;
            this.successMessage = undefined;

            this.$api.post(url, { hostname: host.hostname, ip: host.ip, ip6: host.ip6 })
                .then( apiResult => {
                    this.successMessage = apiResult.message;

                    this.$vToastify.success({
                        canTimeout: true,
                        hideProgressbar: true,
                        duration: 2000,
                        title: "Success!",
                        body: "<span class=\"h3\">" + apiResult.message + "</span>",
                        type: "success"
                    });
                    this.$set(host, 'editMode', false);
                    if (isNew) {
                        this.newHost = Object.assign({});
                    }
                    this.loadDomain();
                })
                .catch(e => {
                    let msg = e.response && e.response.data ? e.response.data.message : "Network error";

                    this.$vToastify.error({
                        canTimeout: true,
                        hideProgressbar: true,
                        duration: 2000,
                        title: "Error!",
                        body: "<span class=\"h3\">" + msg + "</span>",
                        type: "error"
                    });
                    this.errorMessage = msg;
                })
                .then(() => {
                    this.isSaving = false;
                });

        },
        cancelEditHost: function(host) {
            this.$set(host, 'ip', host.savedIp4);
            this.$set(host, 'ip6', host.savedIp6);
            this.$set(host, 'editMode', false);
        },
        deleteHost: function(host) {
            var url = "/domain/" + this.domainId + "/hosts/" + host.hostname;

            this.isSaving = true;
            this.errorMessage = undefined;
            this.successMessage = undefined;

            this.$api.delete(url, { hostname: host.hostname })
                .then( apiResult => {
                    this.successMessage = apiResult.message;

                    this.$vToastify.success({
                        canTimeout: true,
                        hideProgressbar: true,
                        duration: 2000,
                        title: "Success!",
                        body: "<span class=\"h3\">" + apiResult.message + "</span>",
                        type: "success"
                    });
                    this.loadDomain();
                })
                .catch(e => {
                    let msg = undefined;

                    msg = e.response && e.response.data ? e.response.data.message : "Network error";

                    this.$vToastify.error({
                        canTimeout: true,
                        hideProgressbar: true,
                        duration: 2000,
                        title: "Error!",
                        body: "<span class=\"h3\">" + msg + "</span>",
                        type: "error"
                    });
                    this.errorMessage = msg;
                })
                .then(() => {
                    this.isSaving = false;
                });
        }

    },
    mounted: function() {
        this.loadDomain();
    }
}

window.contactMixin = {
    data: {
        constraints: {},
        errors: {},
        list_person_types: [],
        list_countries: [],
        list_judete: [],
        contact_id: 0,
        contact: {
            contact_type: 'R',
            person_type: 'p',
            country_code: 'RO',
        },
    },
    computed: {
        getContactType: function() {
            return this.contact.contact_type.toUpperCase().substr(0, 1);
        },
        isPrivatePerson: function() {
            // make sure the company has the CUI field
            if (this.contact.person_type === 'c') {
                if (!this.contact.cnp_fiscal_code) {
                    this.$set(this.contact, 'cnp_fiscal_code', '');
                }
                if (!this.contact.registration_number) {
                    this.$set(this.contact, 'registration_number', '');
                }
            }
            return this.contact.person_type === "p" || this.contact.person_type === "ap_test";
        },
        requireContactPerson: function() {
            return !this.isPrivatePerson
                && (this.constraints.GENERIC_TLD
                    || this.constraints.EURID
                    || this.getContactType === 'I');
        },
        requireJobTitle: function() {
            return this.constraints.GENERIC_TLD && !this.isPrivatePerson;
        },
        isViesCountry: function() {
            let viesCountries = ['AT', 'BE', 'BG', 'CY', 'CZ', 'DE', 'DK', 'EE', 'ES', 'FI', 'FR', 'HU',
                'IE', 'IT', 'LT', 'LU', 'LV', 'MT', 'NL', 'PL', 'PT', 'SE', 'SI', 'SK', 'RO', 'EL', 'HR', 'GR', 'XI'];

            return viesCountries.includes(this.contact.country_code.toUpperCase());
        },
        isDataValid: function() {
            if (!this.contact.person_type || !this.contact.person_type.length)
                return false;
            if (!this.contact.country_code || !this.contact.country_code.length)
                return false;
            if (!this.contact.address1 || !this.contact.address1.length)
                return false;
            if (!this.contact.city || !this.contact.city.length)
                return false;
            if (!this.contact.state_province || !this.contact.state_province.length)
                return false;
            if (!this.contact.postal_code || !this.contact.postal_code.length)
                return false;
            if (!this.contact.phone || !this.contact.phone.length)
                return false;
            if (!this.contact.email || !this.contact.email.length)
                return false;
            return true;
        }
    },
    methods: {
        saveContact: function() {
            let list = Object.keys(this.constraints);

            let apiURL = "/contact";
            if (this.contact_id) {
                apiURL = apiURL + "/" + this.contact_id;
            }
            apiURL = apiURL + "?constraints=" + list.join(",");

            this.errors = {};
            this.$set(this.app, 'isLoading', true);
            this.$api.post(apiURL, this.contact)
                .then(apiResult => {

                    this.$vToastify.success({
                        canTimeout: true,
                        hideProgressbar: true,
                        duration: 2000,
                        title: "Success!",
                        body: "<span class=\"h3\">" + apiResult.message + "</span>",
                        type: "success"
                    });
                    this.contact_id = apiResult.data.cid;

                    this.$set(this.app, 'isLoading', false);

                    // submit the form *after* the DOM update
                    if (this.$refs.returnSuccess)
                        this.$nextTick(() => this.$refs.returnSuccess.submit());
                })
                .catch(e => {
                    //this.errorMessage = e.response.data.message;
                    if (process.env.NODE_ENV !== 'production') {
                        console.log("got error back: ", e.response.data);
                    }
                    if (e.response.data.data && e.response.data.data.errors)
                        this.errors = e.response.data.data.errors;

                    this.$set(this.app, 'isLoading', false);
                    this.$vToastify.error({
                        canTimeout: true,
                        hideProgressbar: true,
                        duration: 2000,
                        title: "Error!",
                        body: "<span class=\"h3\">" + e.response.data.message + "</span>",
                        type: "error"
                    });
                })
                .then(() => {
                });

        },
        loadContact: function(id) {
            let list = Object.keys(this.constraints);

            //let apiURL = window.baseLangUrl + "web-api/contact/" + id + "?constraints=" + list.join(",");
            let apiURL = "/contact/" + id + "?constraints=" + list.join(",");
            this.errors = {};
            this.$set(this.app, 'isLoading', true);
            this.$api.get(apiURL)
                .then(apiResult => {
                    if (process.env.NODE_ENV !== 'production') {
                        console.log("Got response data: ", apiResult);
                    }
                    this.$set(this, 'contact', apiResult.data);
                    this.$set(this.app, 'isLoading', false);
                })
                .catch(e => {
                    //this.errorMessage = e.response.data.message;
                    if (process.env.NODE_ENV !== 'production') {
                        console.log("got error back: ", e.response.data);
                    }
                    if (e.response.data.data && e.response.data.data.errors)
                        this.errors = e.response.data.data.errors;
                    this.$set(this.app, 'isLoading', false);
                })
                .then(() => {
                });
        },

        checkVAT: function() {
            this.$set(this.app, 'isLoading', true);

            let apiURL = "/cui/" + this.contact.cnp_fiscal_code + "?country=" + this.contact.country_code;
            this.errors = {};
            this.$api.get(apiURL)
                .then(apiResult => {
                    if (process.env.NODE_ENV !== 'production') {
                        console.log("checkVAT success: ", apiResult);
                    }
                    this.contact = Object.assign(this.contact, apiResult.data);

                    if (apiResult.data && apiResult.data.errors)
                        this.errors = apiResult.data.errors;
                    this.$set(this.app, 'isLoading', false);
                })
                .catch(e => {
                    //this.errorMessage = e.response.data.message;
                    if (process.env.NODE_ENV !== 'production') {
                        console.log("checkVAT error: ", e.response.data);
                    }
                    if (e.response.data.data && e.response.data.data.errors)
                        this.errors = e.response.data.data.errors;
                    this.$set(this.app, 'isLoading', false);
                })
                .then(() => {
                });
        },

    },
    mounted: function() {
        if (this.contact_id) {
            this.loadContact(this.contact_id);
        }
    }
}

window.domainConnectMixin = {
    data : {
        connect: {
            services: {
                mail: {
                    parameters: {
                    },
                }
            },
        },
    },
    computed: {
        hasConnectMail: function()
        {
            if (this.remote
                && this.remote.connect
                && this.connect.services
                && this.connect.services.mail) {
                return true;
            }
            return false;
        },

        isValidGoogleCode: function() {
            // we add the parameter to the data structure if we don't have it
            if (!this.connect.services.mail.parameters) {
                if (process.env.NODE_ENV !== 'production') {
                    console.log("Adding googleVerificationCode to parameters");
                }
                this.$set(this.connect.services.mail, 'parameters', {
                    googleVerificationCode: null,
                });
            }

            if (this.connect.services.mail.parameters) {
                let value = this.connect.services.mail.parameters.googleVerificationCode;

                // nothing was entered, we return null => no error message is shown
                if (!value || !value.length) {
                    return null;
                }

                let result = false;
                if (value.match(/^google-site-verification=[-_A-Za-z0-9]+$/)
                    || value.match(/^[-A-Za-z0-9]+\.mx-verification\.google.com/)) {
                    result = true;
                }
                return result;
            }
            return null;
        },
        isValidMX: function() {
            if (!this.connect
                || !this.connect.services
                || !this.connect.services.mail
                || !this.connect.services.mail.mx
                || !this.connect.services.mail.mx.length) {
                return false;
            }

            let mx1 = this.connect.services.mail.mx[0];

            if (parseInt(mx1.pri) >= 0 && parseInt(mx1.pri) <= 65535) {
                if (mx1.server
                    && mx1.server.length) {
                    return true;
                }
            }
            return false;
        },
        hasMailRoService: function() {
            return this.remote
                && this.remote.connect
                && this.remote.connect.services
                && this.remote.connect.services.mail
                && this.remote.connect.services.mail.type === 'MAIL.RO'
                && this.remote.connect.services.mail.provider === 'MAIL.RO';
        },
        hasMailPopularService: function() {
            return this.remote
                && this.remote.connect
                && this.remote.connect.services
                && this.remote.connect.services.mail
                && this.remote.connect.services.mail.type === 'POPULAR';
        },
        hasMailCustomService: function() {
            return this.remote
                && this.remote.connect
                && this.remote.connect.services
                && this.remote.connect.services.mail
                && this.remote.connect.services.mail.type === 'CUSTOM';
        },


    },
    methods: {

        mxEntryRow: function() {
            const structure = [
                {
                    name: "pri",
                    label: this.$t('mail.priority'),
                    width: "col-xs-6 col-sm-3 col-md-2 col-lg-2",
                    help: "0 - 65535",
                    attrs: {
                        type: "number",
                        min: 0,
                        max: 65535,
                    }
                },
                {
                    name: "server",
                    label: this.$t('mail.server_name'),
                    width: "col-xs-12 col-sm-8 col-md-8 col-lg-6",
                    attrs: {
                        placeholder: this.$t('mail.eg_server_name'),
                        type: "text",
                    }
                }
            ];
            return structure;
        },
        changeMailServiceType: function(newType) {
            if (process.env.NODE_ENV !== 'production') {
                console.log("Switching mailType to ", newType);
            }

            if (!this.connect
                || !this.connect.services
                || !this.connect.services.mail) {
                if (process.env.NODE_ENV !== 'production') {
                    console.log("We don't have connect.services.mail structure");
                }
            }

            if (newType === 'GOOGLE') {
                this.connect.services.mail.type = 'POPULAR';
                this.connect.services.mail.provider = 'GOOGLE';
            } else {
                this.connect.services.mail.type = newType;
                this.connect.services.mail.provider = null;
            }
            // mark the service as not configured
            this.connect.services.mail.configured = false;
        },

        hasMailType: function(wantType) {
            return this.remote
                && this.remote.connect
                && this.remote.connect.services
                && this.remote.connect.services.mail
                && this.remote.connect.services.mail.type.toUpperCase() === String(wantType).toUpperCase();
        },

        async submitMailService() {
            // Google
            if (this.connect.services.mail.type === 'POPULAR'
                && this.connect.services.mail.provider === 'GOOGLE'
                && !this.isValidGoogleCode) {
                if (process.env.NODE_ENV !== 'production') {
                    console.log("googleVerification code is incorrect");
                }
                return false;
            }

            let data = {
                type: this.connect.services.mail.type,
                provider: this.connect.services.mail.provider,
            };

            if (this.connect.services.mail.type === 'POPULAR') {
                data.parameters = Object.assign({}, this.connect.services.mail.parameters);
            }
            if (this.connect.services.mail.type === 'CUSTOM') {
                data.mx = Object.assign({}, this.connect.services.mail.mx);
            }

            try {
                this.isLoading = true;
                this.app.isLoading = true;
                let apiResult = await this.$api.post("/domain/" + this.domainId + "/connect/mail", data);

                if (process.env.NODE_ENV !== 'production') {
                    console.log("connect mail api result: ", apiResult);
                }

                // merge remote services
                this.remote = _.merge(this.remote, apiResult.data);
                this.connect.services.mail = _.merge({}, apiResult.data.connect.services.mail);

                if (process.env.NODE_ENV !== 'production') {
                    console.log("Deep merged into remote");
                }
            } catch (e) {

            } finally {
                this.isLoading = false;
                this.app.isLoading = false;
            }
        }
    },
    mounted: function() {
        // copy the services from the remote object
        this.$on('details-loaded', function() {
            console.log("$on details-loaded event, copying connect services");
            if (this.remote.connect) {
                this.connect = _.cloneDeep(this.remote.connect);

                // schedule a reload in 30 seconds
                if (this.connect.services
                    && this.connect.services.mail
                    && this.connect.services.mail.pending) {
                    if (process.env.NODE_ENV !== 'production') {
                        console.log("Scheduling a reload domain in 60s");
                    }
                    setTimeout(() => {
                        this.loadDomain()
                    }, 60 * 1000)
                }
            } else {
                this.connect = Object.assign({});
            }
        });
        if (process.env.NODE_ENV !== 'production') {
            console.log("domainConnectMixin.mounted() finished");
        }
    }
}
