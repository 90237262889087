<template>
    <div>
        <div v-for="(value,index) in newList" style="padding-right: 30px;">
            <div class="form-group has-feedback small-margin">
              <input
                  ref="inputRow"
                  :placeholder="$props.placeholder"
                  type="text"
                  class="form-control"
                  v-model="newList[index]"
                  v-on:input="handleInput"
              >
              <div class="input-inside-button"
                  @click="removeEntry(index)">
                <i class="fa fa-times text-muted hover-red"></i>
              </div>
              <div v-if="canAddElements && newList.length && index === newList.length - 1"
                   class="input-outside-button"
                    @click="addEntry">
                <i class="fa fa-plus hover-primary"></i>
              </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'eureg-input-list',
    props: {
      value: {
        type: Array,
        default: () => []
      },

      placeholder: {
        type: String,
        default: ""
      },

      limit: {
        type: Number,
        default: -1,
      },
    },
    watch: {
        value: function(newVal, oldVal) {
            this.$set(this, 'newList', newVal);
            if (this.newList.length === 0) {
              this.newList.push("");
            }
        }
    },
    data() {
      return {
        newList: this.value,
      }
    },
    created: function() {
        if (this.newList.length === 0) {
          this.newList.push("");
        }
    },
    methods: {
        // v-model expects an @input event
        handleInput (e) {
          this.$emit('input', this.newList)
        },

        removeEntry: function(index) {
            if (index != null)
                this.newList.splice(index, 1)

            if (this.newList.length === 0) {
                this.newList.push("");
            }
            this.handleInput()
        },

        addEntry: function() {
            // we already have the maximum number of elements
            if (!this.canAddElements) {
                return false;
            }
            this.newList.push("");
            // next tick we focus on the newly added input
            this.$nextTick(() => this.$refs.inputRow[this.newList.length - 1].focus())
            this.handleInput()
        },
    },
    computed: {
        canAddElements: function() {
            return !(this.$props.limit > 0
                && this.newList
                && this.newList.length >= this.$props.limit);
        }

    },
}
</script>